const colors = {
  primary: '#89AAFF',
  secondary: '#FFBF60',
  white: '#fff',
  black: '#414661',
  gray: '#5F668C',
  monoGray: '#AEB4D2',
  lightGray: '#DDE1F0',
  lightBlue: '#D4E0FF',
  ghostWhite: '#F2F5FF',
  darkBlue: '#4764AF',
  success: '#23CD67',
  error: '#F16969',
  transparent: 'transparent',
}

export default colors