const size = {
  tiny: '0.75rem',
  xSmall: '0.8rem',
  default: '1rem',
  small: '1.125rem',
  medium: '1.25rem',
  large: '1.5rem',
  xl: '1.75rem',
  xxl: '2rem',
}

const family = {
  primary: `'Prompt', sans-serif`,
}

export default { size, family }
